import React from "react"
import styles from "../discountCard.module.scss"

function PlaceholderDiscountCard() {
  return (
    <div className={styles.placeholder}>
      <div className={styles.animatedBackground}></div>
    </div>
  )
}

export default PlaceholderDiscountCard
