import React from 'react'
import styles from './discountCard.module.scss'

const DiscountCard = ({ type, value, valueType, comment, expiration }) => {
  /**
   * Localize discount data.
   * @param {string} text - input text.
   * @returns {string} - localized text.
   */
  const localizeDiscontData = (text) => {
    const texts = {
      visit: 'Besök rabatt',
      gift: 'Gåva',
    }
    if (text in texts) {
      return texts[text]
    } else {
      return text
    }
  }

  return (
    <div className={styles.card}>
      <div className={styles.discount}>
        <h2 className={styles.value}>{value + valueType}</h2>
        <span className={styles.expiration}>
          {expiration ? `T.o.m ${expiration.slice(0, 10)}` : 'Inget datum'}
        </span>
      </div>
      <div className={styles.description}>
        <h2 className={styles.type}>{localizeDiscontData(type)}</h2>
        <span className={styles.comment}>{comment}</span>
      </div>
    </div>
  )
}

export default DiscountCard
