import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import SignInPage from '../pages/sign-in-page/SignInPage'
import DiscountsAndOffersPage from '../pages/discounts-and-offers-page/DiscountsAndOffersPage'
import VisitsPage from '../pages/visits-page/VisitsPage'
import ContactPage from '../pages/contact-page/ContactPage'
import ProfilePage from '../pages/profile-page/ProfilePage'
import ActivateAccountPage from '../pages/activate-account-page/ActivateAccountPage'
import UnsubscribePage from '../pages/unsubscribe-page/UnsubscribePage'
import ResetPasswordPage from '../pages/reset-password-page/ResetPasswordPage'
import Private from '../custom-hooks/PrivateRoute'

function HomeRouter() {
  const { currentUser } = useAuth()
  return (
    <Routes>
      <Route
        path="/auth/signin"
        element={currentUser ? <Navigate to="/" /> : <SignInPage />}
      />
      <Route
        path="/"
        element={
          <Private>
            <DiscountsAndOffersPage />
          </Private>
        }
      />
      <Route
        path="/discouts"
        element={
          <Private>
            <DiscountsAndOffersPage />
          </Private>
        }
      />
      <Route
        path="/visits"
        element={
          <Private>
            <VisitsPage />
          </Private>
        }
      />
      <Route path="/contact" element={<ContactPage />} />
      <Route
        path="/profile"
        element={
          <Private>
            <ProfilePage />
          </Private>
        }
      />
      <Route path="/auth/forget-password" element={<ResetPasswordPage />} />
      <Route path="/auth/activate-account" element={<ActivateAccountPage />} />
      <Route path="/unsubscribe" element={<UnsubscribePage />} />
    </Routes>
  )
}

export default HomeRouter
