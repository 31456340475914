import React from 'react'
import CenterCard from '../../components/center-card/CenterCard'

function ContactPage() {
  return (
    <div className="container">
      <CenterCard />
    </div>
  )
}

export default ContactPage
