import React, { useState } from "react"
import styles from "./unsubscribeForm.module.scss"
import ErrorMessage from "../error-message/ErrorMessage"
import CustomButton from "../custom-button/CustomButton"

function UnsubscribeForm() {
  // State.
  const [contact, setContact] = useState("")
  const [success, setSuccess] = useState(false)

  // loading and errors states.
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  /**
   * Runs when sign in form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    const textType = validate()
    if (!textType) return
    await unsubscribe(textType)
    setContact("")
  }

  /**
   * Unsubscribes email or mobile number .
   *
   */
  const unsubscribe = async (textType) => {
    let link = ""
    if (textType === "mobile") {
      link = `${process.env.REACT_APP_API_LINK}clients/unsubscribe/?subscribtion_event=unsubscribe_sms&mobile=${contact}`
    }
    if (textType === "email") {
      link = `${process.env.REACT_APP_API_LINK}clients/unsubscribe/?subscribtion_event=unsubscribe_email&email=${contact}`
    }

    try {
      setLoading(true)
      const response = await fetch(link)
      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      setError(error.message || "Något gick fel, vänligen försök igen senare")
    }
  }

  /**
   * Validates and recognize user input.
   * @returns {boolean} - Validation results; the contact type .
   */
  const validate = () => {
    setError("")

    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    const mobileRegex = new RegExp("^[0-9]{10}$")

    if (emailRegex.test(contact)) {
      return "email"
    } else if (mobileRegex.test(contact)) {
      return "mobile"
    } else {
      setError("Texten är varken e-post eller mobilnummer")
      return null
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={success ? `${styles.hidden} ${styles.form}` : styles.form}
      >
        <h1>Avregistrera dig från mottagarlistan</h1>
        <p>Ange ditt mobilnummer eller mejladress som du vill avregistrera.</p>
        <label htmlFor="contact" className={styles.label}>
          E-post eller mobilnummer
        </label>
        <div className={styles.sendSet}>
          <input
            type="text"
            id="contact"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
          />
          <CustomButton
            text="skicka"
            theme="golden"
            pending={loading}
            onClick={handleSubmit}
          />
        </div>
        <ErrorMessage message={error} />
        <br />
      </form>
      <div
        className={
          success
            ? styles.successMsgContainer
            : `${styles.hidden} ${styles.successMsgContainer}`
        }
      >
        <h1>Tack!</h1>
        <p>Du är avregistrerad nu!</p>
      </div>
    </>
  )
}

export default UnsubscribeForm
