import useQueue from '../../custom-hooks/useQueue'
import styles from './centerCard.module.scss'
import placeIcon from './images/placeIcon.svg'
import houresIcon from './images/houresIcon.svg'
import liveIcon from './images/liveIcon.svg'

function CenterCard() {
  const { waitingListLength, serviceListLength } = useQueue()
  let queueStatus = ''
  if (waitingListLength === 0 && serviceListLength === 0) {
    queueStatus = 'Ingen står i kö!'
  } else if (waitingListLength > 0 && serviceListLength === 0) {
    queueStatus =
      'Just nu finns det finns ' + waitingListLength + ' som står i kö'
  } else if (waitingListLength === 0 && serviceListLength > 0) {
    queueStatus =
      'Just nu finns det finns ' + serviceListLength + ' som betjänas'
  } else if (waitingListLength > 0 && serviceListLength > 0) {
    queueStatus =
      'Just nu finns det finns ' +
      waitingListLength +
      ' som står i kö och ' +
      serviceListLength +
      ' som betjänas'
  }

  return (
    <div className={styles.border}>
      <div className={styles.container}>
        <h1>Växjö</h1>
        <div className={styles.infoBlock + ' ' + styles.queueInfoBlock}>
          <div className={styles.header}>
            <img src={liveIcon} alt="icon" />
            <h3>Köstatus live</h3>
          </div>
          <p>{queueStatus}</p>
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.header}>
            <img src={placeIcon} alt="icon" />
            <h3>Adress</h3>
          </div>
          <p>Storgatan 11, Bländapassagen, 352 30 Växjö</p>
        </div>
        <div className={styles.infoBlock}>
          <div className={styles.header}>
            <img src={houresIcon} alt="icon" />
            <h3>Öppettider</h3>
          </div>
          <p>
            Måndag - Fredag 09:00–18:00
            <br /> Lördag 10:00–15:00
            <br /> Söndag stängt
          </p>
        </div>
        <div className={styles.contactBtns}>
          <a href="tel:+4647091091" className="blackBtn">
            Ringa
          </a>
          <a
            href="https://www.bokadirekt.se/places/h-barber-ab-49026"
            className="transparentBtn"
          >
            Boka tid
          </a>
        </div>
      </div>
    </div>
  )
}

export default CenterCard
