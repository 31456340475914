import React, { useState, useEffect } from 'react'
import styles from './profilePage.module.scss'
import EditableText from '../../components/editable-text/EditableText'
import EditableNameLable from '../../components/editable-name-lable/EditableNameLable'
import { useAuth } from '../../contexts/AuthContext'
import Spinner from '../../components/spinner/Spinner'

function ProfilePage() {
  // Use Auth.
  let { signOut, getIdToken, currentUser, authLoading } = useAuth()
  const [loading, setLoading] = useState(authLoading)

  // States.
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [regDate, setRegDate] = useState('')
  const [editMode, setEditMode] = useState(false)

  /**
   * Get client`s profile update state accordingly.
   */
  const getClientProfile = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}clients/${currentUser?.uid}`,
        {
          headers: { Authorization: `Bearer ${await getIdToken()}` },
        }
      )
      const client = await response.json()
      setLoading(false)
      setFirstName(client.firstName)
      setSurname(client.surname)
      setMobile(client.mobile)
      setEmail(client.email)
      setRegDate(client.createdAt?.slice(0, 10))
    } catch (error) {
      // To be handled
      setLoading(false)
      console.log(error.message)
    }
  }

  // Get client profile when component mounts.
  useEffect(() => {
    // Exit if authentication is still in process.
    if (authLoading) return
    // Reset user info when the user log ut.
    if (!currentUser) {
      setFirstName('')
      setSurname('')
      setMobile('')
      setEmail('')
      setRegDate('')
      return
    }

    getClientProfile()
  }, [currentUser])

  // Updating functions.
  const updateMobile = async (newMobile) => {
    const updatedClient = await uppdateClient({ mobile: newMobile })
    setMobile(updatedClient.mobile)
  }
  const updateEmail = async (newEmail) => {
    const updatedClient = await uppdateClient({ email: newEmail })
    setEmail(updatedClient.email)
  }
  const updateName = async (newName) => {
    const updatedClient = await uppdateClient(newName)
    setFirstName(updatedClient.firstName)
    setSurname(updatedClient.surname)
  }

  /**
   * Updates client's profile in backend.
   * @param {object} updatedFields - Upated values, key and value.
   * @returns Updated cleint's profile.
   */
  const uppdateClient = async (updatedFields) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}clients/${currentUser?.uid}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getIdToken()}`,
          },
          body: JSON.stringify(updatedFields),
        }
      )
      const updatedClient = await response.json()
      if (!response.ok) {
        return
      }
      return updatedClient
    } catch (error) {
      // To be handled.
      console.error(error.message)
    }
  }

  if (loading) {
    return (
      <main className="LoadingContainer">
        <Spinner color="#8d8d8d" />
      </main>
    )
  }

  return (
    <main className="container">
      <EditableNameLable
        firstName={firstName}
        surname={surname}
        setNewName={updateName}
        setEditMode={setEditMode}
        editMode={editMode}
      />
      <button className={styles.editBtn} onClick={() => setEditMode(!editMode)}>
        {editMode ? 'Avbryt redigering' : 'Redigera profil'}
      </button>
      <EditableText
        lable="Kund sedan"
        text={regDate}
        setNewText={updateMobile}
        editMode={false}
      />
      <EditableText
        lable="Mobilnummer"
        text={mobile}
        setNewText={updateMobile}
        setEditMode={setEditMode}
        editMode={editMode}
      />
      <EditableText
        lable="Epost"
        text={email}
        setNewText={updateEmail}
        setEditMode={setEditMode}
        editMode={editMode}
      />
      <button className={styles.signOutBtn} onClick={signOut}>
        Logga ut
      </button>
    </main>
  )
}

export default ProfilePage
