import React from 'react'
import UnsubscribeForm from '../../components/unsubscribe-form/UnsubscribeForm'

function UnsubscribePage() {
  return (
    <main className="containerFullScreen">
      <UnsubscribeForm />
    </main>
  )
}

export default UnsubscribePage
