import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ResetPasswordRequestForm from '../../components/reset-password-request-form/ResetPasswordRequestForm'
import styles from './resetPasswordPage.module.scss'

function ResetPasswordPage() {
  // State.
  const [successfulSend, setSuccessfulSend] = useState(false)

  return (
    <main className="containerFullScreen">
      {successfulSend ? (
        <div className={styles.container}>
          <h1>Mejlet har skickats :)</h1>
          <p>
            Du fick ett mejl skickat till den angivna e-postadressen. Följ
            länken, välj ett nytt lösenord och kom tillback för att{' '}
            <NavLink to="/auth/signin" className={styles.link}>
              logga in
            </NavLink>
            .
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <h1>Återställ ditt lösenord</h1>
          <p>
            Har du glömt ditt lösenord? ingen fara. Fyll din registerade
            e-postadress hos oss. Du får sedan ett mejl med en länk för att
            välja ett nytt lösenord.
          </p>
          <ResetPasswordRequestForm setSuccessfulSend={setSuccessfulSend} />
        </div>
      )}
    </main>
  )
}

export default ResetPasswordPage
