import React from 'react'
import VisitsList from '../../components/visits-list/VisitsList'
import styles from './visitsPage.module.scss'

function VisitsPage() {
  return (
    <main className="container">
      <h1 className={styles.title}>Senaste besök</h1>
      <VisitsList />
    </main>
  )
}

export default VisitsPage
