import React from "react"
import styles from "../offerCard.module.scss"

function PlaceholderOfferCard() {
  return (
    <div className={styles.placeholder}>
      <div className={styles.imageContainer}>
        <div className={styles.animatedBackground}></div>
      </div>
      <div className={styles.content}></div>
    </div>
  )
}

export default PlaceholderOfferCard
