import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import styles from './menuBar.module.scss'
import MenuItem from '../menu-item/MenuItem'
import { ReactComponent as Logo } from './images/logo.svg'

function MenuBar() {
  let { currentUser } = useAuth()

  return (
    <div className={styles.bar}>
      <div className={styles.container}>
        <Logo className={styles.logo} />
        {currentUser ? (
          <nav className={styles.menu}>
            <MenuItem lable="Rabatter" link="/discouts" icon="discount" />
            <MenuItem lable="Besök" link="/visits" icon="visit" />
            <MenuItem lable="lokaler" link="/contact" icon="barbershops" />
            <MenuItem lable="Profile" link="/profile" icon="profile" />
          </nav>
        ) : (
          <nav className={`${styles.oneItem} ${styles.menu}`}>
            <MenuItem lable="Logga in" link="/auth/signin" icon="login" />
          </nav>
        )}
      </div>
    </div>
  )
}

export default MenuBar
