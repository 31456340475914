import { useState, useEffect } from 'react'
import styles from './discountList.module.scss'
import DiscountCard from '../discount-card/DiscountCard'
import { useAuth } from '../../contexts/AuthContext'
import PlaceholderDiscountCard from '../discount-card/placeholder/PlaceholderDiscountCard'

const DiscountList = () => {
  // Get current user and id token.
  const { getIdToken, currentUser, authLoading } = useAuth()

  // States.
  const [discounts, setDiscounts] = useState([])
  const [loading, setloading] = useState(authLoading)

  /**
   * Get client discounts and update state accordingly.
   */
  const getDiscounts = async () => {
    setloading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}discounts/?clientid=${currentUser?.uid}`,
        {
          headers: { Authorization: `Bearer ${await getIdToken()}` },
        }
      )
      const discounts = await response.json()
      setloading(false)
      if (!response.ok) {
        return
      }
      setDiscounts(discounts)
    } catch (error) {
      // To be handled
      setloading(false)
      console.log(error.message)
    }
  }

  useEffect(() => {
    // Exit if the authentication is still in state of loading.
    if (authLoading) return
    getDiscounts()
  }, [currentUser, authLoading])

  /**
   * Contains only active disconts.
   */
  const activeDiscounts = discounts?.filter(
    (discount) => discount.state === 'active'
  )

  if (loading) {
    return (
      <ul className={styles.discounts}>
        <PlaceholderDiscountCard />
        <PlaceholderDiscountCard />
      </ul>
    )
  }

  return (
    <>
      {activeDiscounts?.length > 0 ? (
        <ul className={styles.discounts}>
          {activeDiscounts?.map((discount) => (
            <DiscountCard key={discount._id} {...discount} />
          ))}
        </ul>
      ) : (
        <span className={styles.emptyMsg}>
          Det finns inga rabatter add visa{' '}
        </span>
      )}
    </>
  )
}

export default DiscountList
