import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './menuItem.module.scss'
import { ReactComponent as DiscountIcon } from './images/discountIcon.svg'
import { ReactComponent as VisitsIcon } from './images/visitsIcon.svg'
import { ReactComponent as Barbershops } from './images/barbershops.svg'
import { ReactComponent as ProfileIcon } from './images/profileIcon.svg'
import { ReactComponent as LoginIcon } from './images/loginIcon.svg'

function MenuItem({ lable, link = '#', icon, active = true }) {
  const icons = {
    discount: <DiscountIcon className={styles.icon} />,
    visit: <VisitsIcon className={styles.icon} />,
    barbershops: <Barbershops className={styles.icon} />,
    profile: <ProfileIcon className={styles.icon} />,
    login: <LoginIcon className={styles.icon} />,
  }

  return (
    <NavLink
      to={link}
      className={(navData) =>
        navData.isActive
          ? `${styles.menuItem} ${styles.selected}`
          : styles.menuItem
      }
    >
      {icon ? icons[icon] : null}
      <span className={styles.lable}>{lable}</span>
    </NavLink>
  )
}

export default MenuItem
