import React from "react"
import styles from "./offerCard.module.scss"

function OfferCard({ expiration, title, description, imageUrl }) {
  return (
    <div className={styles.container}>
      {expiration ? (
        <span className={styles.exp}>t.o.m {expiration?.substring(0, 10)}</span>
      ) : null}
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={styles.imageContainer}
      ></div>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default OfferCard
