import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ResetPasswordRequestForm from '../../components/reset-password-request-form/ResetPasswordRequestForm'
import styles from './activateAccountPage.module.scss'

function ActivateAccountPage() {
  // State.
  const [successfulSend, setSuccessfulSend] = useState(false)

  return (
    <main className="containerFullScreen">
      {successfulSend ? (
        <div className={styles.container}>
          <h1>Mejlet har skickats :)</h1>
          <p>
            Du fick ett mejl skickat till den angivna e-postadressen. Följ
            länken, välj ett lösenord och kom tillback för att{' '}
            <NavLink to="/auth/signin" className={styles.link}>
              logga in
            </NavLink>
            .
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <h1>Aktivera ditt konto</h1>
          <p>
            Är du redan kund hos oss? Aktivera ditt konto genom att fylla din
            registerade e-postadress hos oss. Du får sedan ett mejl med en länk
            för att välja ett lösenord.
          </p>
          <ResetPasswordRequestForm setSuccessfulSend={setSuccessfulSend} />
        </div>
      )}
    </main>
  )
}

export default ActivateAccountPage
