import React from 'react'
import DiscountList from '../../components/discount-list/DiscountList'
import styles from './discountsAndOffersPage.module.scss'
import OfferList from '../../components/offers-list/OffersList'

function DiscountsAndOffersPage() {
  return (
    <main className="container">
      <h1 className={styles.title}>Dina rabatter</h1>
      <DiscountList />
      <h1 className={styles.title}>Erbjudanden</h1>
      <OfferList />
    </main>
  )
}

export default DiscountsAndOffersPage
