import './App.scss'
import HomeRouter from './routers/HomeRouter'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter } from 'react-router-dom'
import MenuBar from './components/menu-bar/MenuBar'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <MenuBar />
        <HomeRouter />
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
