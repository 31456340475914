import { useState, useEffect } from 'react'
import { getDatabase, ref, onValue } from 'firebase/database'

export default function useQueue() {
  // State declaration.
  const [waitingList, setWaitingList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [serviceCapacity, _setServiceCapacity] = useState(1)

  // Get instance of real time database.
  const realtimeDB = getDatabase()
  // Referance to waiting list in Växjö center document.
  const waitingListRef = ref(realtimeDB, 'vxo01/waitingList')
  // Referance to list of clients being served in Växjö.
  const serviceListRef = ref(realtimeDB, 'vxo01/serviceList')

  /**
   * Converts clients list from Firebase to an array.
   * @param {object} clientsObject Clients list.
   * @returns {Array} Clients list.
   */
  const objectToArray = (clientsObject) => {
    let clientsArray = []
    for (let docId in clientsObject) {
      clientsArray?.push({ docId, ...clientsObject[docId] })
    }
    return clientsArray
  }

  useEffect(() => {
    // Add listeners to waiting and serivce list.
    const unsubscribeWatingList = onValue(waitingListRef, (snapshot) => {
      const updatedWaitingList = snapshot.val()
      setWaitingList(objectToArray(updatedWaitingList))
    })
    const unsubscribServiceList = onValue(serviceListRef, (snapshot) => {
      // Skip updating the service list when it is longer than
      // service capacity because the list will be updated immediately.
      if (snapshot.size > serviceCapacity) return
      const updatedServiceList = snapshot.val()
      setServiceList(objectToArray(updatedServiceList))
    })
    // Detach listeners.
    return () => {
      unsubscribeWatingList()
      unsubscribServiceList()
    }
  }, [])

  return {
    serviceCapacity,
    serviceList,
    waitingList,
    waitingListLength: waitingList.length,
    serviceListLength: serviceList.length,
  }
}
