import styles from './visitsList.module.scss'
import { useState, useEffect } from 'react'
import Spinner from '../spinner/Spinner'
import { useAuth } from '../../contexts/AuthContext'
import { ReactComponent as HairIcon } from './images/hairCut.svg'
import { ReactComponent as HairBeardIcon } from './images/hairBeard.svg'
import { ReactComponent as BeardIcon } from './images/beard.svg'
import { ReactComponent as TreatmentIcon } from './images/treatment.svg'

const VisitsList = () => {
  // Get current user, id token and state of auth .
  const { getIdToken, currentUser, authLoading } = useAuth()

  // States.
  const [visits, setVisits] = useState([])
  const [loading, setLoading] = useState(authLoading)

  /**
   * Get client`s last visits and update state accordingly.
   */
  const getVisits = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}clients/${currentUser?.uid}?fullCopy=true`,
        {
          headers: { Authorization: `Bearer ${await getIdToken()}` },
        }
      )
      const client = await response.json()
      setLoading(false)
      setVisits(client.visits)
    } catch (error) {
      // To be handled
      setLoading(false)
      console.log(error.message)
    }
  }

  useEffect(() => {
    // Exit if the authentication is still in state of loading.
    if (authLoading) return
    getVisits()
  }, [currentUser])

  const orderedVisits = visits?.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1
    if (a.createdAt < b.createdAt) return 1
    return 0
  })

  /**
   * Choose a sutiable icon for the provided service.
   * @param {string} service Provided service.
   * @returns image url.
   */
  const getIcon = (service) => {
    switch (service) {
      case 'Klippning':
        return <HairIcon className={styles.icon} />
      case 'Klippning och skäggvård':
        return <HairBeardIcon className={styles.icon} />
      case 'Skäggvård':
        return <BeardIcon className={styles.icon} />
      case 'Ansiktebehandling':
        return <TreatmentIcon className={styles.icon} />
      default:
        return HairBeardIcon
    }
  }

  if (loading) {
    return (
      <div className="LoadingContainer">
        <Spinner color="#8d8d8d" />
      </div>
    )
  }

  return (
    <>
      {visits && visits?.length > 0 ? (
        <ul className={styles.visits}>
          {orderedVisits?.map((visit) => (
            <li
              key={visit._id}
              className={styles.visit}
              style={{
                animationDelay: orderedVisits.indexOf(visit) * 70 + 'ms',
              }}
            >
              {getIcon(visit.service)}
              <h3 className={styles.visitService}>{visit.service}</h3>
              <h3 className={styles.visitData}>
                {visit.createdAt?.slice(0, 10)}
              </h3>
            </li>
          ))}
        </ul>
      ) : (
        <span className={styles.emptyMsg}>Inget besök än så länge</span>
      )}
    </>
  )
}

export default VisitsList
