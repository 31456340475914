import { useState } from 'react'
import CustomButton from '../../components/custom-button/CustomButton'
import ErrorMessage from '../../components/error-message/ErrorMessage'
import { useAuth } from '../../contexts/AuthContext'
import styles from './resetPasswordRequestForm.module.scss'

const ResetPasswordRequestForm = ({ setSuccessfulSend }) => {
  // State.
  const [email, setEmail] = useState('')

  // loading and errors states.
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // Auth method.
  const { sendPasswordResetLink } = useAuth()

  /**
   * Runs when sign in form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!validate()) return
    try {
      setLoading(true)
      await sendPasswordResetLink(email)
      setLoading(false)
      setSuccessfulSend(true)
      setEmail('')
    } catch (error) {
      setLoading(false)
      setError(localizeErrorMessage(error.code))
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    setError(null)
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (!emailRegex.test(email)) {
      setError('E-post är inte gälltigt')
      return false
    }
    if (!email) {
      setError('Fältet kan inte vare tömt')
      return false
    }
    return true
  }

  /**
   * Localize sign-in error messages.
   */
  const localizeErrorMessage = (message) => {
    const messages = {
      'auth/user-not-found': 'Denna e-postadress är inte registerad hos oss',
      'auth/timeout': 'Det tog längre tid än förväntat!',
      'auth/user-disabled': 'Användaren kunde inte avaktiveras',
    }
    if (message in messages) {
      return messages[message]
    } else {
      return 'Ett fel har inträffat. Vänligen försök igen senare'
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.signInForm}>
      <label htmlFor="email" className={styles.label}>
        E-post
      </label>
      <div className={styles.sendSet}>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomButton
          text="Skicka"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </div>
      <ErrorMessage message={error} />
      <br />
    </form>
  )
}

export default ResetPasswordRequestForm
