import { useState, useEffect } from "react"
import styles from "./offersList.module.scss"
import OfferCard from "../offer-card/OfferCard"
import { useAuth } from "../../contexts/AuthContext"
import PlaceholderOfferCard from "../offer-card/placeholder/PlaceholderOfferCard"

const OfferList = () => {
  // Get current user, id token and state of auth .
  const { getIdToken, currentUser, authLoading } = useAuth()

  // States.
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(authLoading)

  /**
   * Get offers and update state accordingly.
   */
  const getOffers = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_LINK}offers/`, {
        headers: { Authorization: `Bearer ${await getIdToken()}` },
      })
      const offers = await response.json()
      setLoading(false)
      if (!response.ok) return
      setOffers(offers)
    } catch (error) {
      // To be handled
      setLoading(false)
      console.log(error.message)
    }
  }

  useEffect(() => {
    // Exit if the authentication is still in state of loading.
    if (authLoading) return
    getOffers()
  }, [currentUser])

  if (loading) {
    return (
      <ul className={styles.offers}>
        <PlaceholderOfferCard />
        <PlaceholderOfferCard />
      </ul>
    )
  }

  return (
    <>
      {offers?.length > 0 ? (
        <ul className={styles.offers}>
          {offers?.map((offer) => (
            <OfferCard key={offer._id} {...offer} />
          ))}
        </ul>
      ) : (
        <span className={styles.emptyMsg}>
          Det finns inga erbjudande add visa{" "}
        </span>
      )}
    </>
  )
}

export default OfferList
